import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import '../styles/mainNavigation.scss';
import clientImage from "../assets/client-ico-MED.png";
import shoemobileImage from '../assets/truck-icon-MED.png';
import todoImage from '../assets/todo-ico-MED.png';
import messagesImage from '../assets/messages-ico-MED.png';
import staffImage from '../assets/staff-ico-MED.png';
import employeeImage from '../assets/employee-ico-MED.png';
import storeImage from '../assets/store-ico-MED.png';
import posImage from '../assets/pos-ico-MED.png';
import purchasingImage from '../assets/purchasing-ico-LRG.png';
import voucherImage from '../assets/voucher-ico-MED.png';
import inventoryImage from '../assets/inventory-ico-MED.png';
import restockImage from '../assets/restock-ico-MED.png';
import overstockImage from '../assets/overstock-ico-MED.png';
import receivingImage from '../assets/receiving-ico-MED.png';
import reportsImage from '../assets/reports-ico-MED.png';
import vendorImage from '../assets/vendor-ico-MED.png';
import adminImage from '../assets/admin-ico-MED.png';
import websiteImage from '../assets/website-ico-MED.png';
import fulfillmentImage from '../assets/fullfillment-ico-MED.png';
import { Link } from 'react-router-dom';
import ClientDirectory from './Clients/ClientDirectory/ClientDirectory';


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function MainNavigation(props) {
  return (
    <Box className="mainNavigation" sx={{ flexGrow: 1 }}>
      <Grid className="mainGrid" container spacing={2}>
        <Grid item xs={3}>
          <Item className="gridItem client" onClick={()=>props.closeDrawer(false)}><Link to="/clients"><img className="navItemImage" src={clientImage} alt="clients"></img><span className="navItemLabel">Clients</span></Link></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem products" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={shoemobileImage} alt="Shoemobile"></img><span className="navItemLabel">Shoemobile</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem products" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={storeImage} alt="Stores"></img><span className="navItemLabel">Stores</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem products" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={inventoryImage} alt="clients"></img><span className="navItemLabel">Inventory</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem client" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={employeeImage} alt="Employees"></img><span className="navItemLabel">Employees</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem products" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={vendorImage} alt="clients"></img><span className="navItemLabel">Vendors</span></Item>
        </Grid>
        <Grid item xs={6}>
          <Item className="gridItem products" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={posImage} alt="clients"></img><span className="navItemLabel oversize">POS</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem client" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={voucherImage} alt="clients"></img><span className="navItemLabel">Voucher</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem products" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={restockImage} alt="clients"></img><span className="navItemLabel">Restock</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem products" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={overstockImage} alt="clients"></img><span className="navItemLabel">Overstock</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem management" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={reportsImage} alt="clients"></img><span className="navItemLabel">Reports</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem management" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={purchasingImage} alt="clients"></img><span className="navItemLabel">Purchasing</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem management" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={receivingImage} alt="clients"></img><span className="navItemLabel">Receiving</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem management" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={staffImage} alt="clients"></img><span className="navItemLabel">Staff</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem management" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={websiteImage} alt="clients"></img><span className="navItemLabel">Website</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem management" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={adminImage} alt="clients"></img><span className="navItemLabel">Admin</span></Item>
        </Grid>
        <Grid item xs={3}>
          <Item className="gridItem management" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={fulfillmentImage} alt="clients"></img><span className="navItemLabel">Special Order<br/>Fulfillment</span></Item>
        </Grid>

        <Grid item xs={3}>
          <Item className="gridItem messaging" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={messagesImage} alt="clients"></img><span className="navItemLabel">Messages</span></Item>
        </Grid>
        
        <Grid item xs={3}>
          <Item className="gridItem messaging" onClick={()=>props.closeDrawer(false)}><img className="navItemImage" src={todoImage} alt="clients"></img><span className="navItemLabel">To-Do</span></Item>
        </Grid>
      </Grid>
    </Box>
  );
}