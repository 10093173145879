import {React } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Todo from './todo/Todo';
import '../styles/dashboard-res.scss';
import ScheduleDash from './dashboard/ScheduleDash';

const Dashboard = () => {
    return (
        <div className="container-fluid dashBoard">
            <div className="sideBar" style={{backgroundColor: '#ddd'}}>
                <Todo /> 
            </div>
            <div className="mainArea">
                <ScheduleDash/>
            </div>
        </div>
    )
}
export default Dashboard;