import React, { useState } from 'react';
import { Link, NavLink, Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/header.scss';
import summitlogo from '../../assets/summitlogo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap';
import TemporaryDrawer from '../../components/web-components/Drawer'

const Header = () => {
    const [headerNav, setHeaderNav] = useState(false);
    return (
        <div className="headerBar container-fluid">
            <div className="headerContainer">
                <div className="headerLogo"><Link to="/"><img src={summitlogo} alt="Summit Safety Shoes"/></Link></div>
                {/* {headerNav && <ul className="headerMenu">
                    <li><Link to="clients" style={{color: '#fff'}}>Clients</Link></li>
                    <li><Link to="vouchers" style={{color: '#fff'}}>Vouchers</Link></li>
                    <li><Link to="inventory" style={{color: '#fff'}}>Inventory</Link></li>
                    <li><Link to="reports" style={{color: '#fff'}}>Reports</Link></li>
                </ul>} */}
                <TemporaryDrawer />
            </div>
        </div>
    )
}

export default Header;