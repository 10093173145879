import React from 'react';
import '../../styles/item.scss';

const TodoItem = ({todoDetails}) => {
    const formatDate = function (origDate) {
        let d = new Date('2022-2-14');
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let formattedDate = `${mo} ${da}, ${ye}`;
        return formattedDate;
    }

    

    return (
        <>
            <div className={todoDetails.communication_priority + " item itemColumns"}>
                <div className="itemColumn">
                    <h3>{todoDetails.communication_assignment}</h3>
                    
                    <span className="lineOneRight">{todoDetails.communication_store_name}</span>
                    <span className="lineTwoLeft">Assigned to: {todoDetails.communication_assigned}</span>
                    {todoDetails.communication_contact_phone && <span>{todoDetails.communication_contact_phone}</span>}
                    <span className="lineTwoRight">Due: {formatDate(todoDetails.communication_due_date.toString())}</span>
                </div>
            </div>
        </>
    )
}
export default TodoItem;