import * as React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import './drawer.scss';
import MainNavigation from '../MainNavigation';
import menubars from '../../assets/hamburgermenu-ico.png'

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
  'rgba(255,255,255,.0)'
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255,.4)',
  borderLeft: '2px solid #ff000'
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 5,
  height: 30,
  backgroundColor: theme.palette.mode === 'dark' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 'calc(50% - 15px)',
  left: 8,
}));

function SwipeableEdgeDrawer(props) {
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: '100%',
            overflow: 'visible',
            width: '600px',
            borderLeft: '2px solid #ff000'
          },
        }}
      />
      <Box className="drawer" sx={{ textAlign: 'center', pt: 1, borderLeft: '0' }}>
        <Button variant="link" onClick={toggleDrawer(true)}><img src={menubars} alt="Main Menu" style={{color: '#fff', width: '24px'}}/></Button>
      </Box>
      <SwipeableDrawer
        container={container}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: 0,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <MainNavigation closeDrawer={toggleDrawer(false)} />
        </StyledBox>
        <StyledBox
          sx={{
            px: 40,
            pb: 2,
            height: '100%',
            overflow: 'auto',
            width: '100%',
          }}
        >
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;