import React, { useEffect, useState } from 'react';
import TodoItem from '../web-components/Item';
import { Field, Form, Formik } from 'formik';
import Dropdown from '../web-components/Dropdown';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

const Todo = () => {
    const [urgency, setUrgency] = React.useState('lowPriority');
    const [todos, setTodos] = useState(null);
    const todoUrl = "https://ggwsy3n7h3.execute-api.us-east-1.amazonaws.com/dashtodolist";
    const todoFilterUrl = "https://6hq0d18nzi.execute-api.us-east-1.amazonaws.com/selectcattodo";
    const [todoFilters, setTodoFilters] = useState([]);
    const filterTodo = (value) => {
        console.log("todo filter", value);
        // setTodoFilter(value);
    }
    const [todoFilter, setTodoFilter] = useState('All');
    const [todoDropdownLabel, setTodoDropdownLabel] = useState("Filter To-do's");
    const todoFilterFunction = (filter, label) =>{
        if(filter !== 'All') {
            setTodoFilter(filter);
            setTodoDropdownLabel(label);
            console.log("filter", filter);
        } else {
            setTodoFilter("All");
            setTodoDropdownLabel(label);
        }
    }
    useEffect(()=>{
        fetch(todoUrl)
        .then(resp => resp.json())
        .then(resp => {
            console.log("todos", resp);
            setTodos(resp);
        })
    },[todoUrl])

    useEffect(() => {
        fetch(todoFilterUrl)
        .then(result => result.json())
        .then(result => {
            console.log("todo filters", result);
            setTodoFilters(result);
        })
    },[todoFilterUrl])
    return (
        <>
            <h2>Task List</h2>
            <DropdownButton variant="outline-secondary" 
                id="" 
                title={todoDropdownLabel} 
                className="dropdown" 
                style={{minWidth: '200px'}}
                    
            >
                {todoFilters && todoFilters.map((todoFilter, i) => (
                    <DropdownItem key={i} value={todoFilter.filter_by} onClick={() => {todoFilterFunction(todoFilter.filter_by, todoFilter.field_label)}}>{todoFilter.field_label}</DropdownItem>
                ))}
                <DropdownItem onClick={() => {todoFilterFunction('All', 'All')}}>All</DropdownItem>
            </DropdownButton>

            <div className="sideBarWrapper">    
                {todos && todos.filter(item => (todoFilter !== 'All' ? item.communication_assignment_id === todoFilter : todoFilter === 'All')).map((todo, i) =>(
                    <TodoItem key={i} todoDetails={todo}/>
                ))}
            </div>
        </>
    )
}

export default Todo;