import React, {useState, useEffect} from 'react';
import ScheduleItems from './ScheduleItems';
import '../../styles/dashboard-res.scss';
import '../../styles/sidebar.scss';
import * as schedule from '../../mock-data/schedule.json';
import Dropdown from '../web-components/Dropdown';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import DateRangePickerCustom from '../web-components/DateRangePicker';

const scheduleItems = JSON.stringify(schedule);
const ScheduleDash = () => {
    const getCurrentWeek = () => {
        //define a date object variable that will take the current system date
    let todaydate = new Date();

    //find the year of the current date
     var oneJan =  new Date(todaydate.getFullYear(), 0, 1); 

     // calculating number of days in given year before a given date 
     var numberOfDays =  Math.floor((todaydate - oneJan) / (24 * 60 * 60 * 1000)); 

     // adding 1 since to current date and returns value starting from 0 
     var week = Math.ceil(( todaydate.getDay() + 1 + numberOfDays) / 7);   

     //display the calculated result       
     let currentYear = todaydate.getFullYear();
     let currentWeek = currentYear + "-W" + week;
     return currentWeek;
    }

    const [dashboardScheduleData, setDashboardScheduleData] = useState(null);
    const dataUrl = "https://jbdxmybtml.execute-api.us-east-1.amazonaws.com/dashboard_schedule_data";
    //Filters
    const filterByStoreUrl = "https://bqhem9rn19.execute-api.us-east-1.amazonaws.com/ddshoemobile"
    const filterByTypeUrl = "https://89d16vn1rh.execute-api.us-east-1.amazonaws.com/ddschedtype";
    const [filterByStore, setFilterByStore] = useState([]);
    const [filterByType, setFilterByType] = useState([]);

    const [storeFilter, setStoreFilter] = useState('All');
    const [typeFilter, setTypeFilter] = useState('All');
    const [dayOne, setDayOne] = useState(" ");
    const [dayTwo, setDayTwo] = useState(" ");
    const [dayThree, setDayThree] = useState(" ");
    const [dayFour, setDayFour] = useState(" ");
    const [dayFive, setDayFive] = useState(" ");
    const [daySix, setDaySix] = useState(" ");
    const [daySeven, setDaySeven] = useState(" ");
    const [week, setWeek] = useState(getCurrentWeek());

    const [filterByDay1, setFilterByDay1] = useState("");
    const [filterByDay2, setFilterByDay2] = useState("");
    const [filterByDay3, setFilterByDay3] = useState("");
    const [filterByDay4, setFilterByDay4] = useState("");
    const [filterByDay5, setFilterByDay5] = useState("");
    const [filterByDay6, setFilterByDay6] = useState("");
    const [filterByDay7, setFilterByDay7] = useState("");
    const [headerStoreLabel, setHeaderStoreLabel] = useState("All Stores");
    const [headerMonthLabel, setHeaderMonthLabel] = useState("");
    const newArray = [];
    const testDate = new Date();
        const currDate = testDate.getDate();
    const [storeDropdownLabel, setStoreDropdownLabel] = useState("Filter by Store");
    const [typeDropdownLabel, setDropdownLabel] = useState("Filter by Type");

    const dateFilter = (dateField) => {

        let date = new Date(dateField);
        let day = date.getDay(dateField);

        let first = date.getDay(dateField);
        let firstday = new Date(date.setDate(first)).toUTCString();
        return day;
    }

    const dateHeaderFilter = (dateField) => {
        let date = new Date(dateField);
        //let day = date.getDay(dateField);
        let day = date.getDate(dateField);

        return day;
    }

    useEffect(() => {
        setWeek(week);
        let datePieces = week.split("-");
        let y = datePieces[0];
        let wWithLetter = datePieces[1];
        let w = wWithLetter.slice(1);
        var simple = new Date(Date.UTC(y, 0, 1 + (w - 1) * 7));
        var year = simple.getFullYear();
        setHeaderMonthLabel(intl + " " + year)
        var intl = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(simple)
        var dow = simple.getDay();
        var month = simple.getMonth("long") +1;
        setHeaderMonthLabel(intl + " " + year)
        console.log("Month", month);
        var ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        //return ISOweekStart;
        let day1 = new Date(ISOweekStart);
        let day2 = new Date();
        day2.setDate(day1.getDate()+1)
        let day3 = new Date();
        day3.setDate(day1.getDate()+2)
        let day4 = new Date();
        day4.setDate(day1.getDate()+3)
        let day5 = new Date();
        day5.setDate(day1.getDate()+4)
        let day6 = new Date();
        day6.setDate(day1.getDate()+5)

        let day7 = new Date();
        day7.setDate(day1.getDate()+6)

        setDayOne(day1.getDate());
        setDayTwo(day2.getDate());
        setDayThree(day3.getDate());
        setDayFour(day4.getDate());
        setDayFive(day5.getDate());
        setDaySix(day6.getDate());
        setDaySeven(day7.getDate());
        setFilterByDay1(day1);
        setFilterByDay2(day2);
        setFilterByDay3(day3);
        setFilterByDay4(day4);
        setFilterByDay5(day5);
        setFilterByDay6(day6);
        setFilterByDay7(day7);
    }, [week]);

    const getDateOfISOWeek = (e) => {
        setWeek(e.target.value);
        
        let datePieces = week.split("-");
        let y = datePieces[0];
        let wWithLetter = datePieces[1];
        let w = wWithLetter.slice(1);
        var simple = new Date(Date.UTC(y, 0, 1 + (w - 1) * 7));
        var intl = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(simple)
        var dow = simple.getDay();
        var month = simple.getMonth() +1;
        var year = simple.getFullYear();
        setHeaderMonthLabel(intl + " " + year)
        console.log("new Month", month);
        var ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        //return ISOweekStart;
        let day1 = new Date(ISOweekStart);
        let day2 = new Date();
        day2.setDate(day1.getDate()+1)
        let day3 = new Date();
        day3.setDate(day1.getDate()+2)
        let day4 = new Date();
        day4.setDate(day1.getDate()+3)
        let day5 = new Date();
        day5.setDate(day1.getDate()+4)
        let day6 = new Date();
        day6.setDate(day1.getDate()+5)

        let day7 = new Date();
        day7.setDate(day1.getDate()+6)

        setDayOne(day1.getDate());
        setDayTwo(day2.getDate());
        setDayThree(day3.getDate());
        setDayFour(day4.getDate());
        setDayFive(day5.getDate());
        setDaySix(day6.getDate());
        setDaySeven(day7.getDate());
        setFilterByDay1(day1);
        setFilterByDay2(day2);
        setFilterByDay3(day3);
        setFilterByDay4(day4);
        setFilterByDay5(day5);
        setFilterByDay6(day6);
        setFilterByDay7(day7);
    }

    const storeFilterFunction = (filter, label) => {
        if(filter !== 'All') {
            setStoreFilter(filter);
            setStoreDropdownLabel(label);
            setHeaderStoreLabel(label);
        } else {
            setStoreFilter("All");
            setStoreDropdownLabel(label);
            setHeaderStoreLabel("View All");
        }
    }
    const typeFilterFunction = (filter, label) => {
        if(filter !== 'All') {
            setTypeFilter(filter);
            setDropdownLabel(label);
        } else {
            setTypeFilter("All");
            setDropdownLabel(label);
        }
    }
    useEffect(()=>{
        fetch(dataUrl)
        .then(result => result.json())
        .then(result => {
            console.log("data", result);
            setDashboardScheduleData(result);
        })
    },[dataUrl])

    useEffect(() => {
        fetch(filterByStoreUrl)
        .then(result => result.json())
        .then(result => {
            setFilterByStore(result);
        })
    },[])

    useEffect(() => {
        fetch(filterByTypeUrl)
        .then(result => result.json())
        .then(result => {
            setFilterByType(result);
        })
    },[])

    return (
        <div className="container-fluid scheduleContainer">
            <div className="scheduleHeaderLabels">
                <h1>Calendar View</h1><h1>{headerMonthLabel}</h1>
            </div>
            <div className="scheduleHeader">
                <input type="week" className="weekInput" name="week" id="camp-week" min="2022-W10" required onChange={getDateOfISOWeek}></input>
                <DropdownButton variant="outline-secondary" 
                    id="" 
                    title={storeDropdownLabel} 
                    className="dropdown" 
                    style={{minWidth: '200px'}}
                        
                >
                    {filterByStore && filterByStore.map((store, i) => (
                        <DropdownItem key={i} value={store.shoemobile} onClick={() => {storeFilterFunction(store.filter_by, store.field_label)}}>{store.field_label}</DropdownItem>
                    ))}
                    <DropdownItem onClick={() => {storeFilterFunction('All', 'Filter by Store')}}>All</DropdownItem>
                </DropdownButton>
                <DropdownButton variant="outline-secondary" 
                    id="" 
                    title={typeDropdownLabel} 
                    className="dropdown" 
                    style={{minWidth: '200px'}}
                        
                >
                    {filterByType && filterByType.map((type, i) => (
                        <DropdownItem key={i} onClick={() => {typeFilterFunction(type.filter_by, type.field_label)}}>{type.field_label}</DropdownItem>
                    ))}
                    <DropdownItem onClick={() => {typeFilterFunction('All', 'Filter by Type')}}>All</DropdownItem>
                </DropdownButton>
            </div>
            <div className="scheduleColumnHeaders">
                <div className="dateHeaderWrapper">
                    <h3 className="dayHeaders">Monday</h3>
                    <span className="dateHeaderDayLabel">{dayOne}</span>
                </div>
                <div className="dateHeaderWrapper">
                    <h3 className="dayHeaders">Tuesday</h3>
                    <span className="dateHeaderDayLabel">{dayTwo}</span>
                </div>
                <div className="dateHeaderWrapper">
                    <h3 className="dayHeaders">Wednesday</h3>
                    <span className="dateHeaderDayLabel">{dayThree}</span>
                </div>
                <div className="dateHeaderWrapper">
                    <h3 className="dayHeaders">Thursday</h3>
                    <span className="dateHeaderDayLabel">{dayFour}</span>
                </div>
                <div className="dateHeaderWrapper">
                    <h3 className="dayHeaders">Friday</h3>
                    <span className="dateHeaderDayLabel">{dayFive}</span>
                </div>
                <div className="dateHeaderWrapper">
                    <h3 className="dayHeaders">Saturday</h3>
                    <span className="dateHeaderDayLabel">{daySix}</span>
                </div>
                <div className="dateHeaderWrapper">
                    <h3 className="dayHeaders">Sunday</h3>
                    <span className="dateHeaderDayLabel">{daySeven}</span>
                </div>
            </div>
            <div className="scheduleBody">
                <div className="scheduleColumn" style={{backgroundColor: '#ccc'}}>
                    
                    <div className="scheduleColumns">
                        {dashboardScheduleData && dashboardScheduleData.filter(item => dateFilter(item.schedule_date) === 0 && (storeFilter !== 'All' ? item.schedule_store_id === storeFilter : storeFilter == 'All') && (typeFilter !== 'All' ? item.schedule_id_type === typeFilter : typeFilter == 'All') && (new Date(item.schedule_date) > filterByDay1) && new Date(item.schedule_date < filterByDay7)).map((day, i) => (
                            <div key={i}>
                                <ScheduleItems day={day} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="scheduleColumn" style={{backgroundColor: '#ccc'}}>
                    
                    <div className="scheduleColumns">
                        {dashboardScheduleData && dashboardScheduleData.filter(item => dateFilter(item.schedule_date) === 1 && (storeFilter !== 'All' ? item.schedule_store_id === storeFilter : storeFilter == 'All') && (typeFilter !== 'All' ? item.schedule_id_type === typeFilter : typeFilter == 'All') && (new Date(item.schedule_date) > filterByDay1) && new Date(item.schedule_date < filterByDay7)).map((day, j) => (
                            <div key={j}>
                                <ScheduleItems day={day} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="scheduleColumn" style={{backgroundColor: '#ccc'}}>
                    
                    <div className="scheduleColumns">
                        {dashboardScheduleData && dashboardScheduleData.filter(item => dateFilter(item.schedule_date) === 2 && (storeFilter !== 'All' ? item.schedule_store_id === storeFilter : storeFilter == 'All') && (typeFilter !== 'All' ? item.schedule_id_type === typeFilter : typeFilter == 'All') && (new Date(item.schedule_date) > filterByDay1) && new Date(item.schedule_date < filterByDay7)).map((day, i) => (
                            <div key={i}>
                                <ScheduleItems day={day} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="scheduleColumn" style={{backgroundColor: '#ccc'}}>
                    
                    <div className="scheduleColumns">
                        {dashboardScheduleData && dashboardScheduleData.filter(item => dateFilter(item.schedule_date) === 3 && (storeFilter !== 'All' ? item.schedule_store_id === storeFilter : storeFilter == 'All') && (typeFilter !== 'All' ? item.schedule_id_type === typeFilter : typeFilter == 'All') && (new Date(item.schedule_date) > filterByDay1) && new Date(item.schedule_date < filterByDay7)).map((day, i) => (
                            <div key={i}>
                                <ScheduleItems day={day} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="scheduleColumn" style={{backgroundColor: '#ccc'}}>
                    
                    <div className="scheduleColumns">
                        {dashboardScheduleData && dashboardScheduleData.filter(item => dateFilter(item.schedule_date) === 4 && (storeFilter !== 'All' ? item.schedule_store_id === storeFilter : storeFilter == 'All') && (typeFilter !== 'All' ? item.schedule_id_type === typeFilter : typeFilter == 'All') && (new Date(item.schedule_date) > filterByDay1) && new Date(item.schedule_date < filterByDay7)).map((day, i) => (
                            <div key={i}>
                                <ScheduleItems day={day} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="scheduleColumn" style={{backgroundColor: '#ccc'}}>
                    
                    <div className="scheduleColumns">
                        {dashboardScheduleData && dashboardScheduleData.filter(item => dateFilter(item.schedule_date) === 5 && (storeFilter !== 'All' ? item.schedule_store_id === storeFilter : storeFilter == 'All') && (typeFilter !== 'All' ? item.schedule_id_type === typeFilter : typeFilter == 'All') && (new Date(item.schedule_date) > filterByDay1) && new Date(item.schedule_date < filterByDay7)).map((day, i) => (
                            <div key={i}>
                                <ScheduleItems day={day} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="scheduleColumn" style={{backgroundColor: '#ccc'}}>
                    
                    <div className="scheduleColumns">
                    
                        {dashboardScheduleData && dashboardScheduleData.filter(item => dateFilter(item.schedule_date) === 6 && (storeFilter !== 'All' ? item.schedule_store_id === storeFilter : storeFilter == 'All') && (typeFilter !== 'All' ? item.schedule_id_type === typeFilter : typeFilter == 'All') && (new Date(item.schedule_date) > filterByDay1) && new Date(item.schedule_date < filterByDay7)).map((day, i) => (
                            <div key={i}>
                                <ScheduleItems day={day} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ScheduleDash;