import React from "react";
import Signup from "./auth/Signup";
import Login from ".//auth/Login";
import { Container } from "react-bootstrap";
import { AuthProvider } from "../context/AuthContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import Header from "./header/Header";
import Vouchers from "./Vouchers";
import Clients from "./Clients";
import Inventory from "./Inventory";
import Reports from "./Reports";
import SwipeableEdgeDrawer from "./web-components/Drawer";
import TemporaryDrawer from "./web-components/Drawer";
import SwipeableTemporaryDrawer from "./web-components/Drawer";
import Footer from "./Footer";
import '../styles/app.scss';
import ClientDirectory from "./Clients/ClientDirectory/ClientDirectory";

function App() {
  return (
    <>
      <Router>
      
        <Header/>
        
        <Container fluid className="w-100" style={{ minHeight: '80vh', paddingTop: '40px', height: '100%'}}>
          
          <div className="d-flex align-items-center justify-content-center w-100 root">
            <AuthProvider>
              <Routes>
                <Route path="/" element={<Dashboard/>} />
                <Route path="/signup" element={<Signup/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="vouchers" element={<Vouchers/>}/>
                <Route path="clients" element={<ClientDirectory/>}/>
                <Route path="inventory" element={<Inventory/>}/>
                <Route path="reports" element={<Reports/>}/>
              </Routes>
            </AuthProvider>
          </div>
        </Container>
        <Footer/>
      </Router>
    </>
  )
}

export default App;
