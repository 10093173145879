import React from 'react';
import '../styles/footer.scss';

const Footer = () => {
    return (
        <div className="footer">
            <div>© B2B2C. All Rights Reserved | Terms | Privacy</div>
        </div>
    )
}
export default Footer;