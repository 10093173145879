import React, { useEffect } from 'react';
import { useState } from 'react';

const ScheduleItems = (props) => {
    const timeFormat = (time) => {
        let date = new Date(time);
        let hours = date.getHours();
        let AMorPM = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12;
        let minutes = date.getMinutes()<10 ? '0' + date.getMinutes():date.getMinutes();
        let formattedTime = hours + ":" + minutes + AMorPM;
        return formattedTime;
    }
    const [startDate, setStartDate] = useState(props.day.schedule_date_start);
    useEffect(()=>{
        setStartDate(props.day.schedule_date_start);
    },[props.day.schedule_date_start])
    const dateFormat = (date) => {
        let formatDate = new Date(date);
        let formattedDate = new Date(formatDate.setDate(date)).toUTCString();
        return formattedDate;
    }

    return (
        <>
        
            {(() => {
                switch (props.day.schedule_label) {
                    case 'Shoemobile Visit':
                        return (
                            <div className="scheduleItem" style={{backgroundColor: '#fff'}}>
                                <span className="company">{props.day.schedule_client_name}</span><br/>
                                <span>{props.day.schedule_parking_street}</span><br/>
                                <span>{props.day.schedule_parking_city}</span><br/>
                                <span className="contactName">{props.day.schedule_contact_name}</span><br/>
                                <span className="phone">{props.day.schedule_contact_phone}</span><br/>
                                <span className="times">{timeFormat(props.day.schedule_date_start)} - {timeFormat(props.day.schedule_date_end)}</span>
                            </div>
                        );
                    case 'Inventory':
                        return (
                            <div className="scheduleItem todo" style={{backgroundColor: '#09AC22'}}>
                                <span className="typeLabel">{props.day.schedule_label}</span><br/>
                                <span className="times">{timeFormat(props.day.schedule_date_start)} - {timeFormat(props.day.schedule_date_end)}</span>
                            </div>
                        );

                    case 'Maintenance':
                        return (
                            <div className="scheduleItem todo" style={{backgroundColor: '#FF0000'}}>
                                <span className="typeLabel">{props.day.schedule_label}</span><br/>
                                <span className="times">{timeFormat(props.day.schedule_date_start)} - {timeFormat(props.day.schedule_date_end)}</span>
                            </div>
                        );

                    case 'Other':
                        return (
                            <div className="scheduleItem todo" style={{backgroundColor: '#F7B708'}}>
                                <span className="typeLabel">{props.day.schedule_label}</span><br/>
                                <span className="times">{timeFormat(props.day.schedule_date_start)} - {timeFormat(props.day.schedule_date_end)}</span>
                            </div>
                        );
                }
            })()}
        
        </>

    )
}
export default ScheduleItems;