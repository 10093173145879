import { React, useState } from 'react';
import '../../../styles/dashboard-res.scss';
import '../../../styles/Clients/client-directory.scss';
import ClientItem from './ClientItem';
import { Dropdown } from 'react-bootstrap';
import AddClientIcon from "../../../assets/add-ico-MED.png";
import ArrowIcon from "../../../assets/back-ico-MED.png";

const ClientDirectory = () => {
    const [headerLabel, setHeaderLabel] = useState('Clients')
    const [clientStatus, setClientStatus] = useState('active');
    const clientList = [{"name":"Client One", "status": "active"}, {"name":"Client Two", "status": "active"}, {"name":"Client Three", "status": "inactive"}]

    return(
        <>
            <div className="container-fluid pageContainer">
                <div className="scheduleHeaderLabels">
                    <h1 className="pageHeader">{headerLabel} ({clientList.length})</h1>
                    <div className="headerItems">
                        <div className="addClient"><img src={AddClientIcon} alt="Add Client"/></div>
                        <div className="sortAsc"><img className="rotate270" src={ArrowIcon} alt="Add Client"/>A-Z</div>
                        <div className="sortDesc"><img className="rotate90" src={ArrowIcon} alt="Add Client"/>Z-A</div>
                        <Dropdown></Dropdown>
                        {/* <Input></Input> */}
                    </div>
                </div>
                
                <div className="pageContent">
                    <ClientItem clientStatus={clientStatus} clientList={clientList} />
                </div>
            </div>
        </>
    )

}
export default ClientDirectory;