import React from 'react';
import '../../../styles/item.scss';
import '../../../styles/Clients/client-directory.scss';
import X from '../../../assets/delete-ico-LRG.png';
import GearIcon from '../../../assets/account-ico-LRG.png';

const ClientItem = (props) => {

    return (
        <>
            {props.clientList.map((client, i) => (
                
                <div key={i} className={client.status +" item itemColumns clientItem"}>
                    <div className="column growPointFive">
                        <div className="itemHeader">Company</div>
                        <div className="clientType">HYTEST NATIONAL CLIENT - DIRECT BILL</div>
                        <h3 className="name">{client.name}</h3>
                        <div className="clientAdmin"><div className="deleteClient"><img src={X} alt="Delete Client"/></div><div className="settingsIcon"><img src={GearIcon} alt="Client Settings"/></div></div>
                        <div className="clientInfo"><span className="contactEmail">contact@passmoregas.com</span><span className="separator"> |  </span><span className="clientPhone">(555) 555-5555</span></div>
                        <div className="clientStatus">{client.status}</div>
                    </div>
                    <div className="column">
                        <div className="itemHeader">Admins</div>
                        12
                    </div>
                    <div className="column">
                        <div className="itemHeader"># Employees</div>
                            300
                    </div>
                    <div className="column">
                        <div className="itemHeader">Open Vouchers</div>
                            600
                        </div>
                    <div className="column">
                        <div className="itemHeader">Subsidies TD</div>
                        $3,000.00   
                    </div>
                    <div className="column">
                        <div className="itemHeader">Total Sales</div>
                        $5,500.00
                    </div>
                </div>
            ))}
        </>
    )
}
export default ClientItem;